//-------------------
// Footer Component
//-------------------

// PACKAGES
import React, { useState } from 'react'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Link } from "gatsby"
import { Helmet } from 'react-helmet'

// COMPONENTS
import FacebookIcon from "../../../assets/icons/facebook-filled.svg"
import InstagramIcon from "../../../assets/icons/instagram-original.svg"
import TwitterIcon from "../../../assets/icons/twitter-filled.svg"
import Toggle from '../../atoms/Toggle'

// COMPONENT BUILD
const today = new Date()

const Footer = () => {
    const [on, setOn] = useState(false)
    // JSX TEMPLATE
    return (
        <>
        <Helmet>
            <script type="text/javascript">
                {`
                    var onloadCallback = function() {
                        grecaptcha.render('html_element', {
                          'sitekey' : '6LdNE-AZAAAAAFZZsRja59nbrfXAqo4XBDWavkOj'
                        });
                      };
                `}
            </script>
        </Helmet>
        <footer className="py-0 py-md-5 border-top border-ie-gray">
            <div className="container">
                <div className="py-6 row py-lg-0">
                    <div className="order-3 col-12 col-lg-3 d-flex justify-content-center justify-content-lg-start align-items-center flex-column flex-md-row order-lg-1">
                        <p className="mb-0 text-muted font-italic">
                            &copy; {today.getFullYear()} Informeasy.com
                        </p>
                    </div>
                    <div className="order-1 mb-5 col-lg-6 d-flex justify-content-center align-items-center flex-column flex-md-row order-lg-2 mb-lg-0">
                        <ul className="nav justify-content-center">
                            <li className="nav-item position-relative">
                                <Link 
                                    className="py-3 nav-link text-ie-dark" to="/">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item position-relative">
                                <Link
                                    className="py-3 nav-link text-ie-dark"
                                    to="/terms">
                                    Terms
                                </Link>
                            </li>
                            <li className="nav-item position-relative">
                                <Link
                                    className="py-3 nav-link text-ie-dark"
                                    to="/privacy">
                                    Privacy
                                </Link>
                            </li>
                            <li className="nav-item position-relative">
                                <Link
                                    className="py-3 nav-link text-ie-dark"
                                    to="/cookies">
                                    Cookies
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="py-3 nav-link text-ie-dark"
                                    to="/refunds">
                                    Refunds
                                </Link>
                            </li>
                            <li className="nav-item">
                                <button
                                    type="button"
                                    className="py-3 nav-link btn btn-link text-ie-dark"
                                    data-toggle="modal"
                                    data-target=".contact"
                                    aria-label="Contact"
                                    >
                                    Contact
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="modal fade contact bd-example-modal" data-backdrop="true" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="px-5 py-4 modal-header d-block">
                                        <h2 className="mb-3 modal-title w-100" id="exampleModalLabel">Contact us</h2>
                                        <p>Please allow us 48 hours to reply.</p>
                                    </div>
                                    <form action="https://submit-form.com/lljMj3XITSHc8JxmHkA66" method="POST">
                                        <div className="form-group">
                                            <input type="hidden" name="_email.subject" value="New Form Contact" />
                                            <input
                                                type="hidden"
                                                name="_redirect"
                                                value="https://informeasy.com/contact-thankyou/"
                                            />
                                            <label htmlFor="recipient-name" className="col-form-label text-ie-black">Name</label>
                                            <input type="text" name="name" className="form-control" id="recipient-name" aria-describedby="name field" placeholder="Add your name..." required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="recipient-email" className="col-form-label text-ie-black">Email</label>
                                            <input type="email" name="email" className="form-control" id="recipient-email" aria-describedby="email field" placeholder="Your email address here..." required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message-text" className="col-form-label text-ie-black">Message:</label>
                                            <textarea type="text" name="message" className="form-control" id="message-text" aria-describedby="message box" placeholder="And your message..." rows="4" required />
                                        </div>
                                        <input
                                                type="checkbox"
                                                name="_honeypot"
                                                style={{display: `none`}}
                                                tabIndex="-1"
                                                autoComplete="off"
                                        />
                                        <div style={{ display: `flex`, alignItems: `center` }}>
                                            <Toggle onToggleClick={() => setOn(on => !on)} toggled={on} />
                                            <div style={{ marginLeft: `24px` }}>
                                            I confirm that I am not a spam robot.
                                            </div>
                                        </div>
                                        <div className="px-0 pt-2 pb-4 mt-10 mt-sm-0 border-0 modal-footer">
                                            <button type="button" id="dismissModal" style={{lineHeight: `1.9rem`}} className="px-4 shadow-sm btn btn-lg btn-secondary badge-pill text-ie-black" data-dismiss="modal" aria-label="Close">Close</button>
                                            
                                            <TransitionGroup component={null}>
                                                {on ? (
                                                    <CSSTransition classNames="dialog" timeout={300}>
                                                    <button type="submit" style={{lineHeight: `1.9rem`}} className="px-4 text-white shadow btn btn-lg btn-primary badge-pill" aria-label="Submit contact" >Submit</button>
                                                    </CSSTransition>
                                                ) : null}
                                            </TransitionGroup>
                                        
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade earlybird bd-example-modal" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="px-5 pt-6 pb-4 modal-body">
                                <h2 className="mb-3 modal-title w-100" id="exampleModalLabel">Get notified!</h2>
                                    <p>Be the first to know when our Home Guide Booklet is ready for launch.</p>
                                    <p className="text-ie-dark" style={{fontSize:`12px`}}>You will receive a kind reminder in your email inbox when we are ready to launch. We won't send you unwanted emails or share your email address with any third parties.</p>
                                    <hr className="mb-4"/>
                                    <form action="https://submit-form.com/vFFQCozBljRsIdeUgEPv6">
                                        <div className="form-group">
                                            <input type="hidden" name="_email.subject" value="New Early Bird" />
                                            <input
                                                type="hidden"
                                                name="_redirect"
                                                value="https://informeasy.com/signup-thankyou/"
                                            />
                                            <label htmlFor="recipient-name" className="col-form-label text-ie-black">Name</label>
                                            <input type="text" name="name" className="form-control" id="recipient-name" aria-describedby="name field" placeholder="Add your name..." required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="recipient-email" className="col-form-label text-ie-black">Email</label>
                                            <input type="email" name="email" className="form-control" id="recipient-email" aria-describedby="email field" placeholder="Add your email address..." required />
                                        </div>
                                        <input
                                                type="checkbox"
                                                name="_honeypot"
                                                style={{display: `none`}}
                                                tabIndex="-1"
                                                autoComplete="off"
                                        />
                                        <div style={{ display: `flex`, alignItems: `center` }}>
                                            <Toggle onToggleClick={() => setOn(on => !on)} toggled={on} />
                                            <div style={{ marginLeft: `24px` }}>
                                            I confirm that I am not a spam robot.
                                            </div>
                                        </div>
                                        <div className="px-0 pt-2 pb-4 border-0 modal-footer">
                                            <button type="button" id="dismissModal" style={{lineHeight: `1.9rem`}} className="px-4 shadow-sm btn btn-lg btn-secondary badge-pill text-ie-black" data-dismiss="modal" aria-label="Close">Close</button>
                                            
                                            <TransitionGroup component={null}>
                                                {on ? (
                                                    <CSSTransition classNames="dialog" timeout={300}>
                                                    <button type="submit" style={{lineHeight: `1.9rem`}} className="px-4 text-white shadow btn btn-lg btn-primary badge-pill" aria-label="Submit contact" >Submit</button>
                                                    </CSSTransition>
                                                ) : null}
                                            </TransitionGroup>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="order-2 mb-6 col-lg-3 d-flex justify-content-center justify-content-lg-end align-items-center order-lg-3 mb-lg-0">
                        <a href="https://www.facebook.com/theultimatehomeguide" className="px-3 py-3">
                            <img
                                style={{ height: "24px" }}
                                src={FacebookIcon}
                                alt="facebook"
                            />
                        </a>
                        <a href="https://www.instagram.com/informeasy" className="px-3 py-3">
                            <img
                                style={{ height: "24px" }}
                                src={InstagramIcon}
                                alt="facebook"
                            />
                        </a>
                        <a href="https://twitter.com/informeasy" className="px-3 py-3">
                            <img
                                style={{ height: "24px" }}
                                src={TwitterIcon}
                                alt="facebook"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}

export default Footer
